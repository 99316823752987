export const COOKIE = {
  MAINTAIN: '_maintain',
  USER_AGENT: '_ua',
  EUK: '_euk',
} as const

export const QUERY_KEY = {
  RECOMMEND_KEYWORDS: 'recommendKeywords' as const,
  TICKET_FIELD: 'ticketField' as const,
  FAQ: 'faq' as const,
  FAQ_CATEGORY: 'faqCategory' as const,
  BEST_FAQ: 'bestFaq' as const,
  RELATED_FAQ: 'relatedFaq' as const,
  FAQ_SERACH_ARTICLES: 'faqSearchArticles' as const,
  FAQ_CATEGORY_ARTICLES: 'faqCategoryArticles' as const,
  QNA_LIST: 'qnaList' as const,
  QNA: 'qna' as const,
  QNA_COMMENTS: 'qnaComments' as const,
  INQUIRY_TEMPLATE_SECTION_ARTICLES: 'inquiryTemplateSectionArticles' as const,
  INQUIRY_TEMPLATE_ARTICLE: 'inquiryTemplateArticle' as const,
  INQUIRY_RELATE_ARTICLES: 'inquiryRelateArticles' as const,
}

export const LOCAL_STORAGE_KEY = {
  ACCESS_KEY: '__access_key',
  ZENDESK_ACCESS_TOKEN: '__zendesk_access_token',
  ZENDESK_USER_KEY: '__zendesk_user_key',
}
