import axios, {
  AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios'
import Router from 'next/router'

import { API } from '@/constants/api'
import { LOG_PAGE_ID } from '@/constants/log'
import { ROUTER } from '@/constants/router'
import { sendEvent } from '@/libs/mixpanel'

import { isClient } from '../compare'
import { setNewAccessKey } from './config'

const client = axios.create({
  timeout: API.CONFIG.DEFAULT_TIMEOUT,
})

const onRequestConfig = (config: InternalAxiosRequestConfig<any>) => config
const onSuccess = (response: AxiosResponse) => {
  const { headers } = response
  const newAccessKey = headers['new-accesskey']
  if (newAccessKey) {
    setNewAccessKey(newAccessKey)
  }
  return response?.data ?? response
}
const onRequestError = (error: AxiosError) => {
  return Promise.reject(error)
}
const onResponseErrorServer = async (error: AxiosError) => {
  return Promise.reject(error)
}
export const onResponseErrorClient = async (error: AxiosError<any>) => {
  // TODO: 네트워크 연결 실패 관련 에러 분기 필요
  if (error.response) {
    const { status } = error.response

    // 1:1문의에서 429에러는 에러페이지로 이동하지 않고 메세지만 띄움
    if (Number(status) === 429 && window.location.pathname === ROUTER.QNA.INQUIRY) {
      sendEvent(LOG_PAGE_ID.ERROR[429], undefined, { form: 'popup' })
      alert('이용자가 많아\n서비스가 지연되고 있습니다.\n잠시 후 다시 시도해주세요.')
      sendEvent(LOG_PAGE_ID.ERROR[429], 'tap.check')
    } else {
      const query: Record<string, string> = {
        origin: window.location.href,
      }
      if (window.location.search.indexOf('isFirstHistory') > -1 || Router.pathname === ROUTER.MAIN) {
        query.isFirstHistory = 'true'
      }

      const eventName = error.config?.url ?? error.response?.config.url ?? status.toString()
      sendEvent('error', eventName, {
        pathname: window.location.pathname,
        status: status.toString(),
        error,
      })
      Router.replace({
        pathname: ROUTER.ERROR(status),
        query,
      })
    }
  }

  return Promise.reject(error)
}

client.interceptors.request.use(onRequestConfig, onRequestError)
client.interceptors.response.use(onSuccess, isClient ? onResponseErrorClient : onResponseErrorServer)

export const request = <T>(options: AxiosRequestConfig): Promise<T> => client.request(options)
