import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import Router from 'next/router'

import { AccessTokenResponse } from '@/apis/userweb/types'
import { LOCAL_STORAGE_KEY } from '@/constants/key'

import { isClient } from '../compare'
import Logger from '../logger'

export enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const defaultFrontmanHeaders: Record<string, string> = {
  'App-Code': 'tmap',
  Using: 'WEB_CS',
}

export const defaultZendeskHeaders: Record<string, string> = {}

export const getDefaultFrontmanHeaders = () => {
  return { ...defaultFrontmanHeaders }
}

export const getDefaultZendeskHeaders = () => {
  return { ...defaultZendeskHeaders }
}

export const getAccessKey = async () => {
  if (isClient) {
    const query = new URLSearchParams(window.location.search)
    const queryAk = query.get('ak')
    const ak = queryAk || localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) || await TmapApp.getAccessKey()

    if (ak) {
      setAccessKey(ak)
    }
    return ak
  }
  return null
}

export const getEuk = async () => {
  if (isClient) {
    const euk = await TmapApp.getEUK()

    if (euk) {
      setEuk(euk)
    }

    return euk
  }

  return null
}

export const setAccessKey = (ak: string) => {
  defaultFrontmanHeaders.AccessKey = ak
  localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_KEY, ak)
}

export const setNewAccessKey = (ak: string) => {
  if (isClient) {
    const baseUrl = `${window.location.origin}${window.location.pathname}`
    try {
      Router.replace({
        pathname: baseUrl,
        query: {
          ...Router.query,
          ak,
        },
      })
      TmapApp.updateAccessKey({ key: ak })
      setAccessKey(ak)
    } catch (e) {
      Logger.error(e)
    }
  }
}

export const setZendeskAccessToken = (tokenData: AccessTokenResponse) => {
  defaultZendeskHeaders.authorization = `Bearer ${tokenData.access_token}`
  localStorage.setItem(LOCAL_STORAGE_KEY.ZENDESK_ACCESS_TOKEN, tokenData.access_token)
  localStorage.setItem(LOCAL_STORAGE_KEY.ZENDESK_USER_KEY, tokenData.userKey ?? '')
}

export const setEuk = (euk: string) => {
  defaultFrontmanHeaders.euk = euk
}
