import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import {
  DevToolButton,
  DevToolInputText,
  DevToolItem,
  DevTools as TmapDevTools,
} from '@tmap-web-lib/dev-tools-ui'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'
import { useCallback, useRef, useState } from 'react'

import { API } from '@/constants/api'
import { CONFIG, TARGET } from '@/constants/config'
import { COLOR } from '@/styles/color'
import { fontSize } from '@/styles/mixin'
import { METHOD, request } from '@/utils/api'

interface DevToolsProps {
  appVersion?: string;
}

function DevTools({ appVersion }: DevToolsProps) {
  const openBrowserUrlRef = useRef<HTMLInputElement>(null)
  const openServiceByUrlRef = useRef<HTMLInputElement>(null)
  const openServiceByNameServiceRef = useRef<HTMLInputElement>(null)
  const openServiceByNameJsonDataRef = useRef<HTMLInputElement>(null)
  const manualErrorRef = useRef<HTMLInputElement>(null)

  const { mutate: manualError } = useMutation<any, AxiosError, { status: string }>({
    mutationFn: (data) =>
      request({
        url: `${API.PREFIX_URL.USERWEB}/test/manual-error`,
        method: METHOD.POST,
        data,
      }),
    onError: (error) => {
      console.log(error)
    },
  })

  const [isShowDevTools, setIsShowDevTools] = useState(false)

  const handleClickOpenBrowser = useCallback(() => {
    const url = openBrowserUrlRef.current?.value

    if (url) {
      TmapApp.openBrowser({ url })
    }
  }, [])

  const handleClickOpenServiceByUrl = useCallback(() => {
    const url = openServiceByUrlRef.current?.value

    if (url) {
      TmapApp.openServiceByUrl({ url })
    }
  }, [])

  const handleClickOpenServiceByName = useCallback(() => {
    const serviceName = openServiceByNameServiceRef.current?.value
    const jsonData = openServiceByNameJsonDataRef.current?.value

    if (serviceName?.length && jsonData?.length) {
      TmapApp.openServiceByName({ serviceName, jsonData: JSON.parse(jsonData) })
    }
  }, [])

  const handleClickManualError = useCallback(() => {
    const status = manualErrorRef.current?.value

    if (status?.length) {
      manualError({ status })
      setIsShowDevTools(false)
    }
  }, [manualError])

  return (
    <Container>
      <TmapDevTools
        show={isShowDevTools}
        trigger={
          <AppVersionButton
            type="button"
            target={CONFIG.TARGET}
            onClick={() => setIsShowDevTools((isShow) => !isShow)}
          >
            {appVersion ?? 'No Build Version'}
          </AppVersionButton>
        }
      >
        <DevToolItem label="openBrowser">
          <DevToolInputText
            ref={openBrowserUrlRef}
            placeholder="https://www.example.com/test, https://www.example.com/file.pdf, tmap://cs"
            style={{ marginBottom: '6px' }}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <DevToolButton onClick={handleClickOpenBrowser}>이동</DevToolButton>
        </DevToolItem>
        <DevToolItem label="openServiceByUrl">
          <DevToolInputText
            ref={openServiceByUrlRef}
            placeholder="https://www.example.com/test, tmap://cs?pageid=faq"
            style={{ marginBottom: '6px' }}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <DevToolButton onClick={handleClickOpenServiceByUrl}>이동</DevToolButton>
        </DevToolItem>
        <DevToolItem label="openServiceByName">
          <DevToolInputText
            ref={openServiceByNameServiceRef}
            placeholder="ServiceName(cs, transport, life, ...)"
            style={{ marginBottom: '6px' }}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <DevToolInputText
            ref={openServiceByNameJsonDataRef}
            placeholder='JSON Data({ "pageid": "faq", "extra": "{ ..." }) '
            style={{ marginBottom: '6px' }}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <DevToolButton onClick={handleClickOpenServiceByName}>이동</DevToolButton>
        </DevToolItem>
        <DevToolItem label="강제 에러">
          <DevToolInputText
            ref={manualErrorRef}
            placeholder="발생시킬 에러 번호를 입력해주세요. (400, 429, 500 ...)"
            style={{ marginBottom: '6px' }}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <DevToolButton onClick={handleClickManualError}>발생</DevToolButton>
        </DevToolItem>
      </TmapDevTools>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 100%;
  left: 100%;
  z-index: 10000;
`

const AppVersionButton = styled.button<{ target: TARGET }>`
  position: fixed;
  bottom: 8px;
  right: 8px;
  padding: 4px 8px;
  border-width: 2px;
  border-style: solid;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  z-index: 9999;
  opacity: 0.4;
  ${({ theme }) => fontSize(theme, 10)}

  ${({ target }) => {
    switch (target) {
      case 'dtg':
        return css`
          border-color: ${COLOR.semantic.color.green[600]};
          color: ${COLOR.semantic.color.green[600]};
        `
      case 'stg':
        return css`
          border-color: ${COLOR.semantic.color.orange[600]};
          color: ${COLOR.semantic.color.orange[600]};
        `
      case 'rtg':
        return css`
          border-color: ${COLOR.semantic.color.red[600]};
          color: ${COLOR.semantic.color.red[600]};
        `
      default: // dev
        return css`
          border-color: ${COLOR.primary.color.tmobi.blue[600]};
          color: ${COLOR.primary.color.tmobi.blue[600]};
        `
    }
  }}
`

export default DevTools
