import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { PostAccessTokenResponse, postAccessToken } from '@/apis/userweb/postAccessToken'
import { LOCAL_STORAGE_KEY } from '@/constants/key'
import { setAccessKey, setZendeskAccessToken } from '@/utils/api'
import Logger from '@/utils/logger'

import CircularSpinner from '../loading/CircularSpinner'

interface AuthProviderProps extends PropsWithChildren {}

function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)

  const { mutateAsync: getAccessToken } = useMutation<PostAccessTokenResponse, AxiosError, string>({
    mutationFn: (ak) => postAccessToken(ak),
  })

  const initAuth = useCallback(async () => {
    try {
      // RTG 옵션 Check 시 해당 서버(PRD -> RTG, STG -> DTG)로 리다이렉트 처리
      const serverType = await TmapApp.getUserSetting({ key: 'local.server_type' })
      if (serverType === 'rtg') {
        const { href } = window.location
        if (href.startsWith('https://uw.')) {
          window.location.replace(href.replace('https://uw.', 'https://uwrtg.'))
          return
        }

        if (href.startsWith('https://uwstg.')) {
          window.location.replace(href.replace('https://uwstg.', 'https://uwdtg.'))
          return
        }
      }

      const queryAk = router.query.ak as string | undefined
      if (queryAk) {
        // query ak가 존재할 경우 새로운 정보를 받아온다.
        const accessTokenData = await getAccessToken(queryAk)

        setAccessKey(queryAk)
        setZendeskAccessToken(accessTokenData)
      } else {
        // query ak가 없을 경우 기존 정보(LocalStorage 내 정보)를 사용한다.
        const ak =
          localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) || (await TmapApp.getAccessKey())
        // env 내에 ZENDESK_ACCESS_TOKEN이 존재할 경우 해당 값으로 사용한다.
        const accessToken =
          process.env.NEXT_PUBLIC_ZENDESK_ACCESS_TOKEN ||
          localStorage.getItem(LOCAL_STORAGE_KEY.ZENDESK_ACCESS_TOKEN) ||
          ''
        const userKey = localStorage.getItem(LOCAL_STORAGE_KEY.ZENDESK_USER_KEY) || ''

        setAccessKey(ak)
        setZendeskAccessToken({
          access_token: accessToken,
          userKey,
        })
      }
      setIsReady(true)
    } catch (error) {
      Logger.error(error)
    }
  }, [router, getAccessToken])

  useEffect(() => {
    initAuth()
  }, [initAuth])

  return isReady ? (
    children
  ) : (
    <Loading>
      <CircularSpinner />
    </Loading>
  )
}

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`

export default AuthProvider
