import { API } from '@/constants/api'

import { METHOD, getDefaultFrontmanHeaders, getDefaultZendeskHeaders } from './config'
import { request } from './request'

type FrontmanAPI = {
  method: METHOD
  isProxy?: boolean
  path: string
  query?: Record<string, any>
  headers?: Record<string, string>
  body?: any
  signal?: AbortSignal
  timeout?: number
}

const getUrl = (path: string, query?: Record<string, any>) => {
  const pathString = path[0] === '/' ? path : `/${path}`
  const params = new URLSearchParams(query)
  const queryString = params.toString()

  return `${API.ENDPOINT.FRONTMAN}${pathString}${queryString}`
}

export const frontmanApi = <T = any>(args: FrontmanAPI) => {
  const headers = args.headers || {}

  return request<T>({
    method: args.method,
    url: getUrl(args.isProxy ? `/proxy${args.path}` : args.path),
    params: args.query,
    headers: {
      ...getDefaultFrontmanHeaders(),
      ...headers,
    },
    data: args.body,
    signal: args.signal,
    timeout: args.timeout,
  })
}

export const frontmanZendeskApi = <T = any>(args: FrontmanAPI) => {
  const headers = args.headers || {}

  return frontmanApi<T>({
    ...args,
    path: `/zendesk/api/v2${args.path}`,
    headers: {
      ...getDefaultZendeskHeaders(),
      ...headers,
    },
  })
}

export const frontmanZendeskDynamicApi = <T = any>(args: FrontmanAPI, brand: string) => {
  const headers = args.headers || {}

  return frontmanApi<T>({
    ...args,
    path: `/dynamic/zendesk/${brand}/api/v2${args.path}`,
    headers: {
      ...getDefaultZendeskHeaders(),
      ...headers,
    },
  })
}
